import config from "./param"

const Menuitems = [];

Menuitems.push(
  {
    title: 'DashBoard',
    icon: 'pie-chart',
    href: '/home',
  },
);


Menuitems.push(
  {
    title: 'Operaciones',
    icon: 'calendar',
    href: '/op',
    collapse: true,
    children: [
      {
        title: 'Calendario',
        icon: 'calendar',
        href: '/op/calendar',
      },
      {
        title: 'Servicio',
        icon: 'list',
        href: '/op/operacion',
      },

      {
        title: 'Agendados',
        icon: 'book-open',
        href: '/op/agendados',
      },

      {
        title: 'En Traslado',
        icon: 'truck',
        href: '/op/traslados',
      },

      {
        title: 'En Taller',
        icon: 'activity',
        href: '/op/taller',
      },

      {
        title: 'Listos',
        icon: 'check',
        href: '/op/ready',
      },

      {
        title: 'Terminados',
        icon: 'calendar',
        href: '/op/finalizados',
      },

      {
        title: 'Ejecutivos',
        icon: 'users',
        href: '/op/advisers',
      },


      {
        title: 'Choferes',
        icon: 'truck',
        href: '/op/choferes',
      },

    ],
  },



  {
    title: 'Ventas B2C',
    icon: 'bar-chart-2',
    href: '/ventas',
    collapse: true,
    children: [
      {
        title: 'Total Ventas B2C',
        icon: 'grid',
        href: '/ventas/total',
      },
      {
        title: 'Pendientes Pago B2C',
        icon: 'edit',
        href: '/ventas/nopago',
      },
      {
        title: 'Adicionales B2C',
        icon: 'grid',
        href: '/ventas/adicionales',
      },


      {
        title: 'Cotizaciones B2C',
        icon: 'calendar',
        href: '/ventas/cotizaciones',
      },
      {
        title: 'Nueva OT B2C',
        icon: 'calendar',
        href: '/ventas/new',
      },
    ],
  },

  {
    title: 'Empresas B2B',
    icon: 'briefcase',
    href: '/business',
    collapse: true,
    children: [
      {
        title: 'Listado',
        icon: 'grid',
        href: '/business/list',
      },
      {
        title: 'Total Ventas B2B',
        icon: 'grid',
        href: '/business/ventas/total',
      },
      {
        title: 'Pendientes Pago B2B',
        icon: 'edit',
        href: '/business/ventas/nopago',
      },

      {
        title: 'Adicionales B2B',
        icon: 'grid',
        href: '/business/ventas/adicionales',
      },

      {
        title: 'Nueva OT B2B',
        icon: 'calendar',
        href: '/business/ventas/new',
      },
    ],
  },

  {
    title: 'Contabilidad',
    icon: 'bar-chart',
    href: '/accounting',
    collapse: true,
    children: [
      {
        title: 'Facturas',
        icon: 'corner-down-right',
        href: '/accounting/invoice',
      },
      {
        title: 'Ordenes Compra',
        icon: 'corner-down-right',
        href: '/accounting/ordencompra',
      },

      {
        title: 'Empresas',
        icon: 'corner-down-right',
        href: '/accounting/business',
      },


      {
        title: 'Pago Proveedores',
        icon: 'corner-down-right',
        href: '/accounting/proveedores',
      },

      {
        title: 'Pasarela de Pago',
        icon: 'corner-down-right',
        href: '/ventas/transacciones',
      },



    ],
  },

  {
    title: 'Pricing',
    icon: 'dollar-sign',
    href: '/princing',
    collapse: true,
    children: [
      {
        title: 'Pricing',
        icon: 'corner-down-right',
        href: '/princing/b2c',
      },
      {
        title: 'Pricing B2B',
        icon: 'corner-down-right',
        href: '/princing/b2b',
      },

      {
        title: 'Pricing Traslados',
        icon: 'corner-down-right',
        href: '/princing/translados',
      },


      {
        title: 'Repuestos',
        icon: 'corner-down-right',
        href: '/princing/repuestos',
      },



    ],
  },



  {
    title: 'Gestión',
    icon: 'archive',
    href: '/gestion',
    collapse: true,
    children: [
      {
        title: 'Usuarios',
        icon: 'users',
        href: '/gestion/usuarios',
      },

      {
        title: 'Servicios',
        icon: 'shopping-cart',
        href: '/gestion/servicios',
      },
      {
        title: 'Categoria Servicios',
        icon: 'check-circle',
        href: '/gestion/categoria',
      },

      {
        title: 'Cupones',
        icon: 'compass',
        href: '/gestion/cupones',
      },
    ],
  },

  {
    title: 'Talleres',
    icon: 'grid',
    href: '/talleres',
    collapse: true,
    children: [
      {
        title: 'Todos',
        icon: 'briefcase',
        href: '/talleres/all',
      },
      {
        title: 'Horarios',
        icon: 'crosshair',
        href: '/talleres/mecanicos',
      },
      {
        title: 'Día no hábil',
        icon: 'calendar',
        href: '/talleres/feriados',
      },
      {
        title: 'Usuarios',
        icon: 'users',
        href: '/talleres/user',
      },
    ],
  },

  {
    title: 'Vehículos',
    icon: 'award',
    href: '/vehiculos',
    collapse: true,
    children: [
      {
        title: 'Todos',
        icon: 'book-open',
        href: '/vehiculos/vehiculos',
      },
      {
        title: 'Modelos',
        icon: 'chrome',
        href: '/vehiculos/modelos',
      },
    ],
  },

  {
    title: 'Marcas',
    icon: 'chrome',
    href: '/brands',
    collapse: true,
    children: [
      {
        title: 'Todos',
        icon: 'book-open',
        href: '/brands',
      },
      {
        title: 'Agregar Marca',
        icon: 'chrome',
        href: '/brands/new',
      },
    ],
  },

  {
    title: 'Reportes',
    icon: 'cloud',
    href: '/report',
    collapse: true,
    children: [
      {
        title: 'Rep. Operación',
        icon: 'database',
        href: '/report/operaciones',
      },
      {
        title: 'Operaciones',
        icon: 'pie-chart',
        href: '/report/metricasopnew',
      },
      // {
      //   title: 'Ventas',
      //   icon: 'database',
      //   href: '/ventas/analitica',
      // },

      {
        title: 'Analítica',
        icon: 'database',
        href: '/analitica',
      },

      {
        title: 'Marketing',
        icon: 'pie-chart',
        href: '/report/marketing',
      },

      {
        title: 'Ventas B2C',
        icon: 'pie-chart',
        href: '/report/ventasnew',
      },

      {
        title: 'NPS',
        icon: 'pie-chart',
        href: '/report/nps',
      },

      {
        title: 'Ventas B2B',
        icon: 'pie-chart',
        href: '/report/ventasnew2',
      },


      {
        title: 'Adicionales',
        icon: 'pie-chart',
        href: '/report/additional',
      },


      {
        title: 'Gerencia',
        icon: 'database',
        href: '/report/gerencia',
      },


      {
        title: 'Contabilidad',
        icon: 'pie-chart',
        href: '/report/contabilidad',
      },
    ],
  },

  {
    title: 'Cerrar Sesión',
    icon: 'x-circle',
    href: '/auth/close',
  },
);



const nameApp = config.app_name ? config.app_name : "AutoExpertos"
const urlEndPoint = process.env.REACT_APP_URL;

export { Menuitems, nameApp, urlEndPoint };
