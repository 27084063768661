import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import PrivateRouter from './Private';
import EditBrand from '../views/autoexpertos/Brands/EditBrand';

/* ***Layouts**** */
const FullLayout = Loadable(
  lazy(() => import('../layouts/full-layout/FullLayout'))
);
const BlankLayout = Loadable(
  lazy(() => import('../layouts/blank-layout/BlankLayout'))
);
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Login = Loadable(lazy(() => import('../views/authentication/Login3')));
const Close = Loadable(lazy(() => import('../views/authentication/close')));
const Register = Loadable(
  lazy(() => import('../views/authentication/Register'))
);
const ResetPassword = Loadable(
  lazy(() => import('../views/authentication/ResetPassword'))
);

/* ****Pages***** */
const Dashboard = Loadable(
  lazy(() => import('../views/dashboards/Dashboard2'))
);

const Recomendation = Loadable(
  lazy(() => import('../views/autoexpertos/Recomendation'))
);




const Dashboard2 = Loadable(
  lazy(() => import('../views/dashboards/Dashboard3'))
);

const Dashboard3 = Loadable(
  lazy(() => import('../views/dashboards/Dashboard4'))
);

/* ****AutoExpertos***** */
const BookingOperation = Loadable(
  lazy(() => import('../views/autoexpertos/Booking/ProcessView'))
);

const NewBooking = Loadable(
  lazy(() => import('../views/autoexpertos/BookingNew/NewBooking'))
);

const NewBooking2 = Loadable(
  lazy(() => import('../views/autoexpertos/BookingNew/NewBooking'))
);



const NewBookingCotizacion = Loadable(
  lazy(() => import('../views/autoexpertos/BookingNew/NewCotizacion'))
);

const UsuariosView = Loadable(
  lazy(() => import('../views/autoexpertos/Usuarios/UsuariosView'))
);
const TalleresView = Loadable(
  lazy(() => import('../views/autoexpertos/Talleres/View'))
);

const TalleresNew = Loadable(
  lazy(() => import('../views/autoexpertos/Talleres/New'))
);

const ServiciosView = Loadable(
  lazy(() => import('../views/autoexpertos/Servicios/View'))
);
const ServiciosViewEdit = Loadable(
  lazy(() => import('../views/autoexpertos/Servicios/ViewEdit'))
);

const VehiculosView = Loadable(
  lazy(() => import('../views/autoexpertos/Vehiculos/View'))
);

const VehiculosModelView = Loadable(
  lazy(() => import('../views/autoexpertos/Vehiculos/ViewModel'))
);

const TransactionView = Loadable(
  lazy(() => import('../views/autoexpertos/Transaction/View'))
);
const BookingView = Loadable(
  lazy(() => import('../views/autoexpertos/Booking/BookingView'))
);

const VentView = Loadable(
  lazy(() => import('../views/autoexpertos/Booking/BookingViewVent'))
);

const BookingViewNew = Loadable(
  lazy(() => import('../views/autoexpertos/BookingServicios/NewService'))
);

const BookingStatus = Loadable(
  lazy(() => import('../views/autoexpertos/Booking/BookingStatus'))
);


const BookingChoferes = Loadable(
  lazy(() => import('../views/autoexpertos/Choferes'))
);



const BookingStatusNoPay = Loadable(
  lazy(() => import('../views/autoexpertos/Booking/BookingStatusNoPay'))
);

const BookingStatusNoPay2 = Loadable(
  lazy(() => import('../views/autoexpertos/Booking/BookingStatusNoPay'))
);

const SalesWorkFlow = Loadable(
  lazy(() => import('../views/autoexpertos/WorkFlow/SalesWorkFlow'))
);

const AllSales = Loadable(
  lazy(() => import('../views/autoexpertos/Vender/VenderView'))
);

const AllSales2 = Loadable(
  lazy(() => import('../views/autoexpertos/Vender/VenderView'))
);

const AllAditional = Loadable(
  lazy(() => import('../views/autoexpertos/Additional/AllAditional'))
);

const AllAditional2 = Loadable(
  lazy(() => import('../views/autoexpertos/Additional/AllAditional'))
);
const CategoriaServicios = Loadable(
  lazy(() => import('../views/autoexpertos/CategoriaServicios/View'))
);

const CuponesView = Loadable(
  lazy(() => import('../views/autoexpertos/Cupones/View'))
);

const MecanicosView = Loadable(
  lazy(() => import('../views/autoexpertos/Mecanicos/View'))
);

const FeriadosView = Loadable(
  lazy(() => import('../views/autoexpertos/Feriados/View'))
);

const ReportOp = Loadable(
  lazy(() => import('../views/autoexpertos/Reports/Operaciones'))
);

const ReportServicios = Loadable(
  lazy(() => import('../views/autoexpertos/Reports/Servicios'))
);

const ReportVentas = Loadable(
  lazy(() => import('../views/autoexpertos/Reports/Ventas'))
);

const ReportCalificion = Loadable(
  lazy(() => import('../views/autoexpertos/Reports/Calificaciones'))
);

const ReportCupones = Loadable(
  lazy(() => import('../views/autoexpertos/Reports/Cupones'))
);

const CalendarAE = Loadable(
  lazy(() => import('../views/apps/calendar/ACalendar'))
);

const Advisers = Loadable(
  lazy(() => import('../views/autoexpertos/advisers'))
);



const Tablero = Loadable(
  lazy(() => import('../views/autoexpertos/Tablero/tablero'))
);

const BusinessView = Loadable(
  lazy(() => import('../views/autoexpertos/Business/list'))
);

const BusinessViewAccounting = Loadable(
  lazy(() => import('../views/autoexpertos/Business/Accounting'))
);



const BusinessServiceView = Loadable(
  lazy(() => import('../views/autoexpertos/Business/Services'))
);

const BusinessBookingView = Loadable(
  lazy(() => import('../views/autoexpertos/Booking/BusinessBookinView'))
);

const SalesBusinessView = Loadable(
  lazy(() => import('../views/autoexpertos/Business/Vending'))
);

const InvoiceView = Loadable(
  lazy(() => import('../views/autoexpertos/Invoice/Invoice'))
);

const StoreUserView = Loadable(
  lazy(() => import('../views/autoexpertos/StoreUser/StoreUser'))
);

const ReportMarketingMetabase = Loadable(
  lazy(() => import('../views/autoexpertos/Metabaseapp/Marketing'))
);

const ReportVentasMetaBase = Loadable(
  lazy(() => import('../views/autoexpertos/Metabaseapp/Ventas'))
);

const ReportNpsMetaBase = Loadable(
  lazy(() => import('../views/autoexpertos/Metabaseapp/Nps'))
);

const ReportVentasB2BMetaBase = Loadable(
  lazy(() => import('../views/autoexpertos/Metabaseapp/VentasB2B'))
);

const ReportOperacionesMetaBase = Loadable(
  lazy(() => import('../views/autoexpertos/Metabaseapp/Operaciones'))
);

const ReportesDinamicosMetabase = Loadable(
  lazy(() => import('../views/autoexpertos/Metabaseapp/All'))
);

const ViewBrands = Loadable(lazy(() => import('../views/autoexpertos/Brands/ViewBrands')));
const AddBrand = Loadable(lazy(() => import('../views/autoexpertos/Brands/AddBrand')));





/* ****Routes***** */

const Router = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/auth/login" /> },
      {
        path: '/home',
        exact: true,
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'265'} />}
            // component={<Dashboard />}
            profile={[0, 1, 2, 3, 4, 5, 6]}
          />
        ),
      },

      {
        path: '/recomendation',
        exact: true,
        element: (
          <PrivateRouter
            component={<Recomendation />}
            profile={[0, 1, 2, 3, 4, 5, 6]}
          />
        ),
      },



      {
        path: '/analitica',
        exact: true,
        element: (
          <PrivateRouter component={<Dashboard2 />} profile={[0, 1, 2, 3, 5, 6, 4]} />
        ),
      },


      {
        path: '/op/calendar',
        element: (
          <PrivateRouter
            component={<CalendarAE />}
            profile={[0, 1, 2, 3, 4, 5, 6]}
          />
        ),
      },

      {
        path: '/op/analitica',
        element: <PrivateRouter component={<ReportOp />} profile={[0, 1, 5]} />,
      },

      {
        path: '/op/tablero',
        element: <PrivateRouter component={<Tablero />} profile={[0, 1, 4]} />,
      },

      {
        path: '/op/operacion',
        element: (
          <PrivateRouter
            component={<BookingOperation />}
            profile={[0, 1, 2, 5, 4]}
          />
        ),
      },

      {
        path: 'op/choferes',
        element: (
          <PrivateRouter
            component={<BookingChoferes />}
            profile={[0, 1, 2, 5, 4]}
          />
        ),
      },


      {
        path: '/op/view/:idMaintenance/:idRef',
        element: (
          <PrivateRouter component={<BookingView />} profile={[0, 1, 3, 2, 5, 4]} />
        ),
      },

      {
        path: '/op/new/service/:idMaintenance/:idRef',
        element: (
          <PrivateRouter
            component={<BookingViewNew />}
            profile={[0, 1, 2, 5]}
          />
        ),
      },

      {
        path: '/op/agendados',
        element: (
          <PrivateRouter
            component={
              <BookingStatus Status={'pending'} linkref={'agendados'} />
            }
            profile={[0, 1, 2, 5, 4]}
          />
        ),
      },

      {
        path: '/op/traslados',
        element: (
          <PrivateRouter
            component={
              <BookingStatus Status={'moving'} linkref={'traslados'} />
            }
            profile={[0, 1, 2, 5, 4]}
          />
        ),
      },

      {
        path: '/op/taller',
        element: (
          <PrivateRouter
            component={
              <BookingStatus Status={'INPROGRESS'} linkref={'taller'} />
            }
            profile={[0, 1, 2, 5, 4]}
          />
        ),
      },

      {
        path: '/op/ready',
        element: (
          <PrivateRouter
            component={<BookingStatus Status={'READY'} linkref={'ready'} />}
            profile={[0, 1, 2, 5, 4]}
          />
        ),
      },
      {
        path: '/op/finalizados',
        element: (
          <PrivateRouter
            component={
              <BookingStatus Status={'FINISHED'} linkref={'finalizados'} />
            }
            profile={[0, 1, 2, 3, 4, 5, 4]}
          />
        ),
      },

      {
        path: '/op/advisers',
        element: (
          <PrivateRouter
            component={<Advisers />}
            profile={[0]}
          />
        ),
      },



      {
        path: '/business/ventas/new',
        element: (
          <PrivateRouter
            component={<NewBooking2 type={'b2b'} />}
            profile={[0, 1, 2]}
          />
        ),
      },

      {
        path: '/business/ventas/cotizaciones',
        element: (
          <PrivateRouter
            component={<NewBookingCotizacion type={'b2b'} />}
            profile={[0, 1, 2]}
          />
        ),
      },

      {
        path: '/business/ventas/view/:idMaintenance',
        element: (
          <PrivateRouter
            component={<VentView type={'b2b'} />}
            profile={[0, 1, 2, 5]}
          />
        ),
      },

      {
        path: '/business/ventas/workflow',
        element: (
          <PrivateRouter
            component={<SalesWorkFlow type={'b2b'} />}
            profile={[0, 1, 2, 5]}
          />
        ),
      },

      {
        path: '/business/ventas/total',
        element: (
          <PrivateRouter
            component={<AllSales2 type={'b2b'} />}
            profile={[0, 1, 2, 3, 5, 4]}
          />
        ),
      },

      {
        path: 'business/ventas/adicionales',
        element: (
          <PrivateRouter
            component={<AllAditional2 type={'b2b'} />}
            profile={[0, 1, 2, 5, 4]}
          />
        ),
      },

      {
        path: '/business/ventas/nopago',
        element: (
          <PrivateRouter
            component={<BookingStatusNoPay2 type={'b2b'} />}
            profile={[0, 1, 2, 3, 5]}
          />
        ),
      },

      {
        path: '/business/ventas/analitica',
        exact: true,
        element: (
          <PrivateRouter
            component={<Dashboard3 type={'b2b'} />}
            profile={[0, 1, 2, 3, 5]}
          />
        ),
      },

      {
        path: '/business/ventas/transacciones',
        element: (
          <PrivateRouter
            component={<TransactionView type={'b2b'} />}
            profile={[0, 1, 2, 5]}
          />
        ),
      },

      {
        path: '/ventas/new',
        element: (
          <PrivateRouter
            component={<NewBooking type={'b2c'} />}
            profile={[0, 1]}
          />
        ),
      },

      {
        path: '/ventas/cotizaciones',
        element: (
          <PrivateRouter
            component={<NewBookingCotizacion type={'b2c'} />}
            profile={[0, 1]}
          />
        ),
      },

      {
        path: '/ventas/view/:idMaintenance',
        element: (
          <PrivateRouter
            component={<VentView type={'b2c'} />}
            profile={[0, 1, 2, 5]}
          />
        ),
      },

      {
        path: '/ventas/workflow',
        element: (
          <PrivateRouter
            component={<SalesWorkFlow type={'b2c'} />}
            profile={[0, 1, 2, 5]}
          />
        ),
      },

      {
        path: '/ventas/total',
        element: (
          <PrivateRouter
            component={<AllSales type={'b2c'} />}
            profile={[0, 1, 3, 5, 4]}
          />
        ),
      },

      {
        path: '/ventas/adicionales',
        element: (
          <PrivateRouter
            component={<AllAditional type={'b2c'} />}
            profile={[0, 1, 5, 4]}
          />
        ),
      },

      {
        path: '/ventas/nopago',
        element: (
          <PrivateRouter
            component={<BookingStatusNoPay type={'b2c'} />}
            profile={[0, 1, 3, 5]}
          />
        ),
      },

      {
        path: '/ventas/analitica',
        exact: true,
        element: (
          <PrivateRouter
            component={<Dashboard3 type={'b2c'} />}
            profile={[0, 1, 2, 3, 5, 6]}
          />
        ),
      },

      {
        path: '/ventas/transacciones',
        element: (
          <PrivateRouter
            component={<TransactionView type={'b2c'} />}
            profile={[0, 1, 3, 5]}
          />
        ),
      },

      {
        path: '/gestion/usuarios',
        element: (
          <PrivateRouter component={<UsuariosView />} profile={[0, 1, 2, 3, 5, 6, 4]} />
        ),
      },

      {
        path: '/gestion/usuarios/:idUser',
        element: (
          <PrivateRouter component={<UsuariosView />} profile={[0, 1, 2, 5]} />
        ),
      },

      {
        path: '/business/services',
        element: (
          <PrivateRouter
            component={<BusinessServiceView />}
            profile={[0, 2, 3, 4, 5]}
          />
        ),
      },

      {
        path: '/business/list',
        element: (
          <PrivateRouter
            component={<BusinessView />}
            profile={[0, 2, 3, 4, 5, 6]}
          />
        ),
      },

      {
        path: '/business/pay',
        element: (
          <PrivateRouter
            component={<SalesBusinessView />}
            profile={[0, 2, 3, 4, 5]}
          />
        ),
      },

      {
        path: '/talleres/all',
        element: (
          <PrivateRouter component={<TalleresView />} profile={[0, 5, 3, 6, 4]} />
        ),
      },

      {
        path: '/talleres/user',
        element: (
          <PrivateRouter component={<StoreUserView />} profile={[0, 4]} />
        ),
      },

      {
        path: '/gestion/servicios/id/:IdService',
        element: (
          <PrivateRouter component={<ServiciosViewEdit />} profile={[0]} />
        ),
      },

      {
        path: '/gestion/servicios',
        element: (
          <PrivateRouter component={<ServiciosView />} profile={[0, 5, 6, 4]} />
        ),
      },

      {
        path: '/gestion/categoria',
        element: (
          <PrivateRouter component={<CategoriaServicios />} profile={[0, 5, 6, 4]} />
        ),
      },
      {
        path: '/vehiculos/vehiculos',
        element: (
          <PrivateRouter
            component={<VehiculosView />}
            profile={[0, 1, 2, 3, 4, 5, 6, 4]}
          />
        ),
      },
      {
        path: '/vehiculos/:idVehicle',
        element: (
          <PrivateRouter
            component={<VehiculosView />}
            profile={[0, 1, 2, 3, 4, 5]}
          />
        ),
      },

      {
        path: '/vehiculos/modelos',
        element: (
          <PrivateRouter
            component={<VehiculosModelView />}
            profile={[0, 1, 2, 3, 4, 5, 6, 4]}
          />
        ),
      },

      {
        path: '/vehiculos/placas',
        element: (
          <PrivateRouter
            component={<VehiculosView />}
            profile={[0, 1, 2, 3, 4, 5]}
          />
        ),
      },

      {
        path: '/vehiculos/combustibles',
        element: (
          <PrivateRouter
            component={<VehiculosView />}
            profile={[0, 1, 2, 3, 4]}
          />
        ),
      },

      {
        path: '/gestion/cupones',
        element: <PrivateRouter component={<CuponesView />} profile={[0, 5, 6]} />,
      },

      {
        path: '/talleres/new',
        element: <PrivateRouter component={<TalleresNew />} profile={[0, 5]} />,
      },

      {
        path: '/talleres/mecanicos',
        element: (
          <PrivateRouter component={<MecanicosView />} profile={[0, 5, 6, 4]} />
        ),
      },

      {
        path: '/talleres/feriados',
        element: (
          <PrivateRouter component={<FeriadosView />} profile={[0, 5, 6]} />
        ),
      },
      {
        path: '/transacciones/:idTransaction',
        element: (
          <PrivateRouter
            component={<TransactionView />}
            profile={[0, 1, 2, 3, 5]}
          />
        ),
      },
      {
        path: '/report/operaciones',
        element: <PrivateRouter component={<ReportOp />} profile={[0, 3, 5, 6, 4]} />,
      },
      {
        path: '/report/servicios',
        element: (
          <PrivateRouter component={<ReportServicios />} profile={[0, 5]} />
        ),
      },
      {
        path: '/report/calificaciontaller',
        element: (
          <PrivateRouter component={<ReportCalificion />} profile={[0, 5]} />
        ),
      },

      {
        path: '/report/cupones',
        element: (
          <PrivateRouter component={<ReportCupones />} profile={[0, 5]} />
        ),
      },

      {
        path: '/report/ventas',
        element: (
          <PrivateRouter component={<ReportVentas />} profile={[0, 5]} />
        ),
      },

      {
        path: '/accounting/invoice',
        element: (
          <PrivateRouter
            component={<InvoiceView />}
            profile={[0, 5, 3]}
          />
        ),
      },

      {
        path: '/accounting/pagos',
        element: (
          <PrivateRouter component={<TransactionView />} profile={[0, 3, 5]} />
        ),
      },

      {
        path: '/report/marketing',
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'1'} />}
            profile={[0, 3, 6, 5]}
          />
        ),
      },

      {
        path: '/report/ventasnew',
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'2'} />}
            profile={[0, 1, 3, 5, 6]} />
        ),
      },

      {
        path: '/report/ventasnew2',
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'67'} />}
            profile={[0, 2, 3, 5, 6]}
          />
        ),
      },

      {
        path: '/report/operacionesnew',
        element: (
          <PrivateRouter
            component={<ReportOperacionesMetaBase />}
            profile={[0, 3, 5, 6]}
          />
        ),
      },

      {
        path: 'business/reporte',
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'133'} />}
            profile={[0, 2, 3, 5, 6]}
          />
        ),
      },

      {
        path: 'report/contabilidad',
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'397'} />}
            profile={[0, 5]}
          />
        ),
      },



      {
        path: 'report/metricasopnew',
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'232'} />}
            profile={[0, 3, 5, 6, 4]}
          />
        ),
      },


      {
        path: '/accounting/business',
        element: (
          <PrivateRouter
            component={<BusinessViewAccounting />}
            profile={[0, 5]}
          />
        ),
      },

      {
        path: '/report/nps',
        element: (
          <PrivateRouter
            component={<ReportNpsMetaBase />}
          />
        ),
      },



      {
        path: 'report/gerencia',
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'364'} />}
            profile={[0]}
          />
        ),
      },



      {
        path: ' princing/translados',
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'364'} />}
            profile={[0]}
          />
        ),
      },


      {
        path: 'report/additional',
        element: (
          <PrivateRouter
            component={<ReportesDinamicosMetabase uuid={'331'} />}
            profile={[0]}
          />
        ),
      },



      {
        path: 'accounting/ordencompra',
        element: (
          <PrivateRouter
            component={<></>}
            profile={[100]}
          />
        ),
      },

      {
        path: 'accounting/proveedores',
        element: (
          <PrivateRouter
            component={<></>}
            profile={[100]}
          />
        ),
      },


      {
        path: 'princing/b2c',
        element: (
          <PrivateRouter
            component={<></>}
            profile={[100]}
          />
        ),
      },



      {
        path: 'princing/b2b',
        element: (
          <PrivateRouter
            component={<></>}
            profile={[100]}
          />
        ),
      },






      {
        path: 'princing/translados',
        element: (
          <PrivateRouter
            component={<></>}
            profile={[100]}
          />
        ),
      },



      {
        path: 'princing/repuestos',
        element: (
          <PrivateRouter
            component={<></>}
            profile={[100]}
          />
        ),
      },

      {
        path: '/brands',
        element: (
          <PrivateRouter
            component={<ViewBrands />}
            profile={[0, 1, 2, 3, 4, 5, 6]}
          />
        ),
      },

      {
        path: '/brands/new',
        element: (
          <PrivateRouter
            component={<AddBrand />}
            profile={[0, 1, 2, 3, 4, 5, 6]}
          />
        ),
      },

      {
        path: '/brands/:brandId',
        element: (
          <PrivateRouter
            component={<EditBrand />}
            profile={[0, 1, 2, 3, 4, 5, 6]}
          />
        ),
      },

      // { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: 'auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'login', element: <Login /> },
      { path: 'close', element: <Close /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
