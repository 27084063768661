import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import PageContainer from "../../../components/container/PageContainer";
import Breadcrumb from "../../../layouts/full-layout/breadcrumb/Breadcrumb";
import { apiGetBrandById, apiUpdateBrand } from "../../../apis";
import { useParams, useNavigate } from 'react-router-dom';

const EditBrand = () => {
    const { brandId } = useParams();
    const navigate = useNavigate();
    const [brandName, setBrandName] = useState("");
    const [model, setModel] = useState("");
    const [typo, setTypo] = useState("");

    useEffect(() => {
        const fetchBrand = async () => {
            try {
                const brand = await apiGetBrandById(brandId);
                setBrandName(brand.brand);
                setModel(brand.model);
                setTypo(brand.typo);
            } catch (error) {
                console.error("Error fetching brand:", error);
                alert("Error al cargar la marca");
            }
        };

        if (brandId) {
            fetchBrand();
        }
    }, [brandId]);

    const handleUpdateBrand = async () => {
        if (brandName && model && typo) {
            try {
                const response = await apiUpdateBrand(brandId, {
                    brand: brandName,
                    model,
                    typo
                });

                if (response) {
                    alert("Marca actualizada exitosamente");
                    navigate('/brands');
                } else {
                    alert("Error al actualizar la marca");
                }
            } catch (error) {
                console.error("Error updating brand:", error);
                alert("Error al actualizar la marca");
            }
        }
    };

    return (
        <PageContainer title="Editar Marca" description="Editar una Marca">
            <Breadcrumb
                title={"Editar Marca"}
                items={[
                    {
                        to: "/brands",
                        title: "MARCAS",
                    },
                ]}
            />

            <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Nombre de la Marca"
                        variant="outlined"
                        fullWidth
                        value={brandName}
                        onChange={(e) => setBrandName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <TextField
                        label="Modelo"
                        variant="outlined"
                        fullWidth
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                        <InputLabel>Tipo</InputLabel>
                        <Select
                            value={typo}
                            onChange={(e) => setTypo(e.target.value)}
                        >
                            <MenuItem value={"CAMIONETA XL Y ALTA GAMA"}>CAMIONETA XL Y ALTA GAMA</MenuItem>
                            <MenuItem value={"SEDAN - SUV"}>SEDAN - SUV</MenuItem>
                            <MenuItem value={"CAMIONETA PICK UP"}>CAMIONETA PICK UP</MenuItem>
                            <MenuItem value={"City"}>City</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpdateBrand}
                    >
                        Actualizar Marca
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => navigate('/brands')}
                        style={{ marginLeft: '10px' }}
                    >
                        Cancelar
                    </Button>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default EditBrand; 